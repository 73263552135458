import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography } from "@mui/material";

const EditStock = ({ productID, currentStock, onStockUpdated }) => {
  const [stock, setStock] = useState(currentStock);

  const handleChange = (e) => {
    setStock(e.target.value);
  };

  const handleUpdateStock = async () => {
    try {
      await axios.put(`http://localhost:5000/products/update-stock`, {
        ProductID: productID,
        Stock: stock,
      });
      onStockUpdated();
    } catch (error) {
      console.error("Error updating stock:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Edit Stock</Typography>
      <TextField
        label="Stock"
        name="Stock"
        value={stock}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateStock}
        sx={{ mt: 2 }}
      >
        Update Stock
      </Button>
    </Box>
  );
};

export default EditStock;
